import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from 'src/app/services/course.service';
import { SEO } from 'src/app/utils/seo';

declare var document: any;

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
  providers: [CourseService, SEO]
})
export class CourseComponent implements OnInit {

    course: any;
    // Get uri
    private uri: string = this.route.snapshot.paramMap.get('course');

    constructor(
        private title: Title,
        private courseService: CourseService,
        private route: ActivatedRoute,
        private seo: SEO,
        ) {
        // Get Course
        this.course = this.courseService.getCourse(this.uri);
    }

    ngOnInit() {
        let _title = this.course.name + " | Faciso";
        this.title.setTitle(_title);
        this.seo.update([
            { name: 'keywords', content: this.course.meta_keywords },
            { name: 'title', content: _title },
            { name: 'description', content: this.course.meta_description },
            { name: 'og:title', content: _title },
            { name: 'og:description', content: this.course.meta_description },
        ]);
        setTimeout(() => {
            this.iShow(null);
        }, 1000);
    }

    iShow(show) {
        if(show == 0 || show == 1) {
            let num = show;
            show = show == 0 ? 'zero' : 'dois';
            document.getElementById(`SVG_arrow ${num}_id`).style.display = "none";
            document.querySelector(`.show.${show}`).style.display = "none";
            document.querySelector(`.hidden.${show}`).style.display = "initial";
        } else {
            var show = document.querySelectorAll('.show');
            if(show.length == 2) {
                show[0].style.display = "initial";
                show[1].style.display = "initial";
            } else {
                document.querySelector('.show').style.display = "initial";
            }

            var hidden = document.querySelectorAll('.hidden');
            if(hidden.length == 2) {
                hidden[0].style.display = "none";
                hidden[1].style.display = "none";
            } else {
                document.querySelector('.hidden').style.display = "none";
            }
        }
    }
}
