import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

declare var RDStationForms: any;

@Component({
  selector: 'app-ombudsman',
  templateUrl: './ombudsman.component.html',
  styleUrls: ['./ombudsman.component.scss']
})
export class OmbudsmanComponent implements OnInit {

    constructor(private title: Title) { }

    ngOnInit() {
        // Set title at page
        this.title.setTitle("Ouvidoria - Faciso");

        // RDStationForms
        new RDStationForms('ouvidoria-bf5d6b625e4523b87f5b', 'UA-93483267-1').createForm();
    }
}
