import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { QuemSomosComponent } from './quem-somos/quem-somos.component';
import { RepositorioInstitucionalComponent } from './repositorio-institucional/repositorio-institucional.component';
import { CpaComponent } from './cpa/cpa.component';
import { NapComponent } from './nap/nap.component';
import { SharedModule } from '../shared/shared.module';
import { CoresModule } from '../cores/cores.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { RouterModule } from '@angular/router';
import { LibraryComponent } from './library/library.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OmbudsmanComponent } from './ombudsman/ombudsman.component';
import { CourseComponent } from './course/course.component';
import { ContactComponent } from './contact/contact.component';
import { Tour360Component } from './tour360/tour360.component';
import { CancellationPoliciesComponent } from './cancellation-policies/cancellation-policies.component';
import { InstitutionalComponent } from './institutional/institutional.component';

@NgModule({
    declarations: [HomeComponent, QuemSomosComponent, RepositorioInstitucionalComponent, CpaComponent, NapComponent, PrivacyPolicyComponent, TermsOfUseComponent, LibraryComponent, NotFoundComponent, OmbudsmanComponent, CourseComponent, ContactComponent, Tour360Component, CancellationPoliciesComponent, InstitutionalComponent],
  imports: [
    CommonModule,
    SharedModule,
    CoresModule,
    RouterModule
  ]
})
export class PagesModule { }
