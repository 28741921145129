import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cores-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {

    show: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
