import { Injectable } from "@angular/core";
import coursesMock from "./moking/courses.mock";

@Injectable()
export class CourseService {
    constructor() {}

    public getCourse(uri: string) {
        var course: object;

        coursesMock.filter(e => {
            if(e.uri  === uri) course = e;
        })

        if(course === undefined)
            window.location.href = '404';

        return course;
    }

    public getCourses() {
        var courses: Array<object> = [];

        coursesMock.map( e => {
            courses.push({
                name: e.name,
                description: e.brief,
                icon: e.icon,
                uri: e.uri
            })
        });

        return courses;
    }
}
