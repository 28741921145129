import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact/contact.component';
import { DescriptionComponent } from './description/description.component';
import { BannerComponent } from './banner/banner.component';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { TitlePageComponent } from './title-page/title-page.component';

@NgModule({
    declarations: [
        ContactComponent,
        DescriptionComponent,
        BannerComponent,
        NavbarComponent,
        TitlePageComponent,
    ],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        DescriptionComponent,
        ContactComponent,
        BannerComponent,
        TitlePageComponent,
        NavbarComponent
    ],
})
export class SharedModule { }
