import { Component, OnInit } from '@angular/core';
import { SEO } from './utils/seo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SEO],
})
export class AppComponent implements OnInit{
    constructor( private seo: SEO) {
        this.seo.create();
    }

    ngOnInit():void {
        //Google Tag Manager
        this.addScriptCode("(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PB9NSBQ')");
        // this.addScriptCode("(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-PB9NSBQ')");
        this.addNoScript('<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PB9NSBQ" height="0" width="0" style="display:none;visibility:hidden"></iframe>');

        //RD Station
        this.addScriptBody('https://d335luupugsy2.cloudfront.net/js/loader-scripts/b1ca60d7-46df-4ba6-8a55-a589748f38c4-loader.js');

        // Vlibras
        this.addScriptCode("new window.VLibras.Widget('https://vlibras.gov.br/app')");

        // Google Tag Manager
        this.addScript('https://www.googletagmanager.com/gtag/js?id=UA-93483267-1', () => {
            this.addScriptCode("window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'UA-93483267-1'); ");
        })
    }

    public addScriptBody(url) {
        let node = document.createElement('script');
        node.type = 'text/javascript';
        node.async = true;
        node.src = url;

        document.getElementsByTagName('body')[0].appendChild(node);
    }

    public addScriptCode(code) {
        let node = document.createElement('script');
        node.type = 'text/javascript';
        node.innerHTML = code;
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    public addNoScript(content) {
        let node = document.createElement('noscript');
        node.innerHTML = content;
        document.getElementsByTagName('body')[0].appendChild(node);
    }

    public addScript(url, callback) {
        let node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
        if(callback != ''){
            callback();
        }
    }
}
