import { Component, OnInit } from '@angular/core';
import { CourseService } from 'src/app/services/course.service';
import { SEO } from 'src/app/utils/seo';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [SEO, CourseService]
})
export class HomeComponent implements OnInit {

    cards: Array<object>;

    constructor( private seo: SEO, private course: CourseService) {
        this.seo.update([
            {
                name: "description",
                content:
                    "Com professores renomados e aulas práticas presenciais, a FACISO atua há mais de 10 anos oferecendo cursos de pós-graduação para médicos.",
            },
            { name: "title", content: "Home | Faciso" },
            { name: "og:title", content: "Home | Faciso" },
            {
                name: "og:description",
                content:
                    "Com professores renomados e aulas práticas presenciais, a FACISO atua há mais de 10 anos oferecendo cursos de pós-graduação para médicos.",
            },
        ]);

        this.cards = this.course.getCourses();
    }

    ngOnInit() { }
    boxs = [{
        name: 'A conexão com a sua carreira começa aqui.',
        description: 'Adquira conhecimento e desenvolva o seu potencial.',
        image: '../../../assets/img/icon-people.png',
    },{
        name: 'Avance na fronteira do conhecimento.',
        description: 'Torne-se um profissional com múltiplas possibilidades de atuação.',
        image: '../../../assets/img/icon-rocket.png',
    },{
        name: 'Seja protagonista na sua área de atuação.',
        description: 'Esteja preparado(a) para os desafios diários do mercado de trabalho.',
        image: '../../../assets/img/icon-book.png',
    }];

}
