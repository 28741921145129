import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cancellation-policies',
  templateUrl: './cancellation-policies.component.html',
  styleUrls: ['./cancellation-policies.component.scss']
})
export class CancellationPoliciesComponent implements OnInit {

    constructor(private title: Title) {
        // Set title at page
        this.title.setTitle(
            "Política de cookies"
        );
    }

  ngOnInit() {
  }

}
