import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent implements OnInit {

    @Input() image: string;
    @Input() img_alt: string;
    @Input() title: string;
    @Input() description: string;


  constructor() { }

  ngOnInit() {
  }

}
