import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cores-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

    isConsented: boolean = false;

    cookieName: string = '_InfogenCookie';
    cookieValue: string = 'f3ee8b35f6ae90c682';
    cookieExpires: number = 365;
    cookiePath: string = '/';

    constructor() {
        this.isConsented = (this.getCookie(this.cookieName) == this.cookieValue) ? true : false;
    }

    ngOnInit(){ }

    private getCookie(name: string) {
        let ca: Array<string> = document.cookie.split(';');
        let caLen: number = ca.length;
        let cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    private setCookie() {
        let d:Date = new Date();
        d.setTime(d.getTime() + this.cookieExpires * 24 * 60 * 60 * 1000);
        let expires:string = `expires=${d.toUTCString()}`;
        let cpath:string = this.cookiePath ? `; path=${this.cookiePath}` : '';
        document.cookie = `${this.cookieName}=${this.cookieValue}; ${expires}${cpath}`;
    }

    consent(isConsent: boolean) {
        if (!isConsent) {
            return this.isConsented;
        } else if (isConsent) {
            this.setCookie();
            this.isConsented = true;
        }
    }
}
