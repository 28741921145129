import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SEO } from 'src/app/utils/seo';

declare var RDStationForms: any;

@Component({
  selector: 'page-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    constructor(private title: Title, private seo: SEO ) { }

    ngOnInit() {
        const _title = "Fale Conosco | Faciso";
        // Set title at page
        this.title.setTitle(_title);
        this.seo.update([
            { name: "title", content: _title },
            {
                name: "description",
                content:
                    "Quer fazer uma reclamação, sugerir alguma melhoria ou fazer um elogio? Entre em contato com a Ouvidoria Faciso.",
            },
            { name: "og:title", content: _title },
            {
                name: "og:description",
                content:
                    "Quer fazer uma reclamação, sugerir alguma melhoria ou fazer um elogio? Entre em contato com a Ouvidoria Faciso.",
            },
        ]);

        // RDStationForms
        new RDStationForms('fale-conosco-0aca51feab238b276b48', 'UA-93483267-1').createForm();
    }
}
