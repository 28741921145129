import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

export declare type MetaDefinition = {
    content?: string;
    name?: string;
}

@Injectable()
export class SEO {

    constructor(private metaTag: Meta) {}

    update(tags: MetaDefinition[]) {
        tags.map(tag => {
            this.metaTag.updateTag(
                {name: tag.name, content: tag.content}
            );
        });
    }

    create() {
        this.metaTag.addTags([
            {
                name: "description",
                content:
                    "Com professores renomados e aulas práticas presenciais, a Faciso atua há mais de 10 anos oferecendo cursos de pós-graduação para médicos.",
            },
            {
                name: "keywords",
                content:
                    "graduação, especialização, medicina, emergência, urgência, medicina intensiva, pediatria",
            },
            { name: "title", content: "Faciso" },

            { name: "og:url", content: window.location.href },
            { name: "og:site_name", content: "Faciso" },
            { name: "og:title", content: "Faciso" },
            {
                name: "og:description",
                content:
                    "Com professores renomados e aulas práticas presenciais, a Faciso atua há mais de 10 anos oferecendo cursos de pós-graduação para médicos.",
            },
            {
                name: "og:image",
                content: "https://www.facisogoiania.com.br/assets/img/logo-faciso.png",
            },
        ]);
    }
}
