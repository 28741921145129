import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CancellationPoliciesComponent } from './pages/cancellation-policies/cancellation-policies.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CourseComponent } from './pages/course/course.component';
import { HomeComponent } from './pages/home/home.component';
import { QuemSomosComponent } from './pages/quem-somos/quem-somos.component';
import { RepositorioInstitucionalComponent } from './pages/repositorio-institucional/repositorio-institucional.component';
import { CpaComponent } from './pages/cpa/cpa.component';
import { NapComponent } from './pages/nap/nap.component';
import { InstitutionalComponent } from './pages/institutional/institutional.component';
import { LibraryComponent } from './pages/library/library.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OmbudsmanComponent } from './pages/ombudsman/ombudsman.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { Tour360Component } from './pages/tour360/tour360.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'quem-somos', component: QuemSomosComponent },
    { path: 'repositorio-institucional', component: RepositorioInstitucionalComponent },
    { path: 'cpa', component: CpaComponent },
    { path: 'nap', component: NapComponent },
    { path: 'politica-de-cookies', component: CancellationPoliciesComponent },
    { path: 'termos-de-uso', component: TermsOfUseComponent },
    { path: "fale-conosco", component: ContactComponent },
    { path: 'politica-de-privacidade', component: PrivacyPolicyComponent },
    { path: 'curso/:course', component: CourseComponent },
    { path: 'biblioteca', component: LibraryComponent },
    { path: 'tour-360', component: Tour360Component },
    { path: 'institucional', component: InstitutionalComponent },
    { path: 'ouvidoria', component: OmbudsmanComponent },
    { path: '', component: HomeComponent },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'top',
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
