import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cores-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    isShowCon: boolean = false;
    isShowCen: boolean = false;

    constructor() { }

    ngOnInit() { }

}
