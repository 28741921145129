import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

    @Input() type_course: boolean;
    @Input() video: string;
    @Input() type_video: string; //Set type video value: 'youtube'(ID), 'video'(MP4)
    @Input() sub_title: string;
    @Input() first_sub_title: string;
    @Input() first_title: string;
    @Input() second_sub_title: string;
    @Input() second_title: string;
    @Input() resume: string;
    @Input() pag: string;

    courseShow: string;
    params: Params;
    couse: string;
    // current_component: string;

    constructor(private route: ActivatedRoute){
        // Get params of Cuouse
        this.couse = this.route.snapshot.paramMap.get('pos');

        // this.current_component = this.route.routeConfig.component.name;
    }

    ngOnInit() {
        this.courseShow = this.type_course ? 'true' : 'false';

        if(this.type_video == "youtube") {
            this.video = 'https://youtube.com/embed/'+this.video+'?autoplay=1&controls=0&loop=1&mute=1&showinfo=0&autohide=0';
        }


        //  adjust the video on the smartphot
        // if(/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //     document.querySelector('#video').setAttribute("style",'top: -110px;');
        // }
    }
}
