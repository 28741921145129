import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CookieComponent } from './cookie/cookie.component';
import { PopupComponent } from './popup/popup.component';
import { VlibrasComponent } from './vlibras/vlibras.component';

@NgModule({
    declarations: [
      FooterComponent,
      HeaderComponent,
      CookieComponent,
      PopupComponent,
      VlibrasComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        CookieComponent,
        PopupComponent,
        VlibrasComponent
    ]
})
export class CoresModule { }
