import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {

    pagina: string;
    link: string;

    constructor(private title: Title) {
        this.title.setTitle("Biblioteca | Graduação Faciso");
    }

    ngOnInit() {}

    setDialog(pag: string, link: string) {
        this.pagina = pag;
        this.link = link;
    }

    resetDialog(){
        this.pagina = '';
        this.link = '';
    }

    redirectExternalPage() {
        window.open (this.link, "_newtab" );
        this.resetDialog();
    }
}
