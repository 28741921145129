import { Component, OnInit } from '@angular/core';

declare var window: any;

@Component({
  selector: 'cores-vlibras',
  templateUrl: './vlibras.component.html',
  styleUrls: ['./vlibras.component.scss']
})
export class VlibrasComponent implements OnInit {

    constructor() {
        new window.VLibras.Widget('https://vlibras.gov.br/app');
    }

    ngOnInit() { }
}
