import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import detectDevice from 'src/app/utils/detectDevice';

@Component({
    selector: 'cores-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

    show: boolean = false;

    private innerHeight: number = window.innerHeight; // get scroll


    ngOnInit() { }

    @ViewChild('menu') menu;
    @HostListener('window:scroll', ['$event'])
    onHeight() {
        this.innerHeight = window.scrollY;

        if(this.innerHeight > 350) {
            this.menu.nativeElement.style.position = 'fixed';
            this.menu.nativeElement.style.background = '#FFF';
            this.menu.nativeElement.style.transition = 'all 0.5s ease-in-out';
            this.menu.nativeElement.style.boxShadow = '1px -25px 37px 0px #FFF';
            this.menu.nativeElement.style.paddingBottom = '15px';
        } else {
            this.menu.nativeElement.style.position = 'absolute';
            this.menu.nativeElement.style.background = '#FFF';
            this.menu.nativeElement.style.boxShadow = 'none';
        }
    }
}
