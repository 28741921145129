import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SEO } from 'src/app/utils/seo';

declare var document: any;

@Component({
  selector: 'app-nap',
  templateUrl: './nap.component.html',
  styleUrls: ['./nap.component.scss'],
  providers: [SEO]
})
export class NapComponent implements OnInit {

    course: any;
    // Get uri
    private uri: string = this.route.snapshot.paramMap.get('course');

    constructor(
        private title: Title,
        private route: ActivatedRoute,
        private seo: SEO,
        ) {
    }

    ngOnInit() {
        let _title = "Nap | Graduação Faciso";
        this.title.setTitle(_title);
        this.seo.update([
            { name: 'keywords', content: 'Nap'},
            { name: 'title', content: 'Nap' },
            { name: 'description', content: 'Nap' },
            { name: 'og:title', content: 'Nap' },
            { name: 'og:description', content: 'Nap' },
        ]);
    }

    iShow(show) {
        if(show == 0 || show == 1) {
            let num = show;
            show = show == 0 ? 'zero' : 'dois';
            document.getElementById(`SVG_arrow ${num}_id`).style.display = "none";
            document.querySelector(`.show.${show}`).style.display = "none";
            document.querySelector(`.hidden.${show}`).style.display = "initial";
        } else {
            var show = document.querySelectorAll('.show');
            if(show.length == 2) {
                show[0].style.display = "initial";
                show[1].style.display = "initial";
            } else {
                document.querySelector('.show').style.display = "initial";
            }

            var hidden = document.querySelectorAll('.hidden');
            if(hidden.length == 2) {
                hidden[0].style.display = "none";
                hidden[1].style.display = "none";
            } else {
                document.querySelector('.hidden').style.display = "none";
            }
        }
    }
}
