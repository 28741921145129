import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

    constructor(private title: Title) {
        // Set title at page
        this.title.setTitle("Termos de Uso - Faciso");
    }

    ngOnInit() { }
}
