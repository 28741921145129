import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

declare var RDStationForms: any;

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

    show: boolean = false;

    constructor(private title: Title) { }

    ngOnInit() {
        // Set title at page
        this.title.setTitle(
            "Política de Privacidade e Proteção de Dados | Graduação Faciso"
        );

        // RDStationForms Solicitação de dados
        new RDStationForms('solicitacao-de-dados-96ce57366af515b87c83', 'UA-93483267-1').createForm();

        // RDStationForms Solicitação de exclusão de dados
        new RDStationForms('exclusao-de-dados-43c039b6c23f8593360b', 'UA-93483267-1').createForm();
    }
}
